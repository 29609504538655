import { CHART_COLORS } from '../components/contstants'
import type {
  IDashboardResultExpiryProfile,
  IDashboardResultAverageInitialRent,
  IDashboardResultExpiryProfileByArea,
  IDashboardResultExpiryProfileByRent,
} from '~/composables/useAPI/useApiDashboard'
import type { ChartColumnData, ChartColumnDataItem } from '../components/types'
import { getYearsInRange } from './getYearsInRange'
import { DashboardQuery } from '@dashboard'

const initializeDataArray = (
  years: string[],
  name: string,
  color: string,
  value: ChartColumnDataItem = { y: null },
): ChartColumnData => {
  return {
    name,
    data: years.map(() => value),
    color,
  }
}

// TODO: each chart has its own structure, dunno if it's possible to have one function for all
export const convertDashboardExpiryProfileResultToChart = (
  result:
    | IDashboardResultExpiryProfile
    | IDashboardResultExpiryProfileByArea
    | IDashboardResultExpiryProfileByRent,
  startYear: number,
  endYear: number,
  type:
    | DashboardQuery.EXPIRY_PROFILE
    | DashboardQuery.EXPIRY_PROFILE_BY_AREA
    | DashboardQuery.EXPIRY_PROFILE_BY_RENT,
): ChartColumnData[] => {
  const data: ChartColumnData[] = []
  const years = getYearsInRange(result, startYear, endYear)

  const mapper = {
    [DashboardQuery.EXPIRY_PROFILE]: {
      y: (v: IDashboardResultExpiryProfile[string][string]) => v.count,
    },
    [DashboardQuery.EXPIRY_PROFILE_BY_AREA]: {
      y: (v: IDashboardResultExpiryProfileByArea[string][string]) =>
        v.percentageOfArea * 100,
    },
    [DashboardQuery.EXPIRY_PROFILE_BY_RENT]: {
      y: (v: IDashboardResultExpiryProfileByRent[string][string]) =>
        v.percentageOfArea * 100,
    },
  }

  for (const year in result) {
    for (const resultKey in result[year]) {
      if (years.includes(year)) {
        const dataIndex = data.findIndex((item) => item.name === resultKey)

        if (dataIndex >= 0) {
          const yearIndex = years.indexOf(year)
          data[dataIndex].data[yearIndex] = {
            y: result[year][resultKey]
              ? mapper[type].y(result[year][resultKey]) // TODO: fix type
              : null,
            link: result[year][resultKey]?.link,
          }
        } else {
          const newData: ChartColumnData = {
            name: resultKey,
            data: years.map(() => ({
              y: null,
            })),
            color: CHART_COLORS[data.length % CHART_COLORS.length],
          }
          const yearIndex = years.indexOf(year)
          newData.data[yearIndex] = {
            y: result[year][resultKey]
              ? mapper[type].y(result[year][resultKey]) // TODO: fix type
              : null,
            link: result[year][resultKey]?.link,
          }
          data.push(newData)
        }
      }
    }
  }

  return data
}

export function convertDashboardAverageinitialRentResultToChart(
  result: IDashboardResultAverageInitialRent,
  startYear: number,
  endYear: number,
): ChartColumnData[] {
  const data: ChartColumnData[] = []
  const years = getYearsInRange(result, startYear, endYear)

  for (const year in result) {
    for (const resultKey in result[year]) {
      if (years.includes(year)) {
        const dataIndex = data.findIndex((item) => item.name === resultKey)

        if (dataIndex >= 0) {
          const yearIndex = years.indexOf(year)
          data[dataIndex].data[yearIndex] = {
            y: result[year][resultKey]?.average,
            link: result[year][resultKey]?.link,
          }
        } else {
          const newData: ChartColumnData = {
            name: resultKey,
            data: years.map(() => ({
              y: null,
            })),
            color: CHART_COLORS[data.length % CHART_COLORS.length],
          }
          const yearIndex = years.indexOf(year)
          newData.data[yearIndex] = {
            y: result[year][resultKey]?.average,
            link: result[year][resultKey]?.link,
          }
          data.push(newData)
        }
      }
    }
  }

  return data
}
